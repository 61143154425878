import request from '@/utils/request'
const serivceBase = require('@/config/serviceConfig')

// 同步链接兑换码-校验接口
export function checkForSyncLink(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/checkForSyncLink`,
    method: 'post',
    data
  })
}
// 兑换码同步兑换
export function syncVerify(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/syncVerify`,
    method: 'post',
    data
  })
}
// 发送短信
export function sendCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/sendVerifyCodeForSync`,
    method: 'post',
    data
  })
}
// 兑换码列表查询
export function listRedeemCode(data) {
  return request({
    url: `${serivceBase.IMS}/redeemCodeStream/listRedeemCodeForSyncLink`,
    method: 'post',
    data
  })
}
